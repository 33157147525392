import * as React from "react";
import {ComponentType} from 'react';
import {
    ArrayInput,
    BooleanInput,
    Create,
    Datagrid,
    DateInput,
    Edit,
    EditButton,
    Filter,
    FormDataConsumer,
    List,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    DateField,
    TextInput,
    Toolbar,
    SaveButton,
    useEditContext,
} from 'react-admin';
import {oemRules, independentRules} from "./oemRules";
import {useFormContext} from 'react-hook-form';


export interface Location {
    is_active: boolean,
    "filter_email_resend_leads": boolean,
    "filter_oem_resend_leads": boolean,
    "filter_crm_resend_leads": boolean,
    "name": string
    "notes": string
    "cpo_vehicles_email_delivery_enabled": boolean,
    "cpo_vehicles_oem_delivery_enabled": boolean,
    "cpo_vehicles_crm_delivery_enabled": boolean,
    "email_forwarding_addresses": Array<string>,
    "email_structure": string,
    "filter_incomplete_leads": boolean,
    "id": string,
    "zip": string,
    "parts_department_code": string,
    "service_department_code": string,
    "primary_department_code": string,
    "fortellis_subscription_id": string,
    "merchant_id": string,
    "invoice_paid_delivery_enabled": boolean,
    "invoice_partial_refund_delivery_enabled": boolean,
    "invoice_refunded_delivery_enabled": boolean,
    "new_vehicles_email_delivery_enabled": boolean,
    "new_vehicles_oem_delivery_enabled": boolean,
    "new_vehicles_crm_delivery_enabled": boolean,
    "oem_code": string,
    "oem_id": number,
    "service_email_delivery_enabled": boolean,
    "service_oem_delivery_enabled": boolean,
    "service_crm_delivery_enabled": boolean,
    "parts_email_delivery_enabled": boolean,
    "parts_oem_delivery_enabled": boolean,
    "parts_crm_delivery_enabled": boolean,
    "other_email_delivery_enabled": boolean,
    "other_oem_delivery_enabled": boolean,
    "other_crm_delivery_enabled": boolean,
    "used_oem_vehicles_email_delivery_enabled": boolean,
    "used_oem_vehicles_oem_delivery_enabled": boolean,
    "used_oem_vehicles_crm_delivery_enabled": boolean,
    "used_vehicles_email_delivery_enabled": boolean,
    "used_vehicles_oem_delivery_enabled": boolean,
    "used_vehicles_crm_delivery_enabled": boolean,
    "vendor": string,
    "crm_id": number,
}

const CRMs = [
    {id: 1, name: 'Affinitiv'},
    {id: 2, name: 'Auto Dealer Plus'},
    {id: 3, name: 'Auto Manager'},
    {id: 4, name: 'Auto-IT'},
    {id: 5, name: 'AutoRaptor'},
    {id: 6, name: 'DealerCenter'},
    {id: 7, name: 'DealerMine'},
    {id: 8, name: 'DealerPeak'},
    {id: 9, name: 'DealerSocket'},
    {id: 10, name: 'Drivecentric'},
    {id: 11, name: 'eLead'},
    {id: 12, name: 'Focus (Reynolds & Reynolds)'},
    {id: 13, name: 'Other'},
    {id: 14, name: 'Mitchell 1'},
    {id: 15, name: 'ProMax'},
    {id: 16, name: 'Selly'},
    {id: 17, name: 'Tekion'},
    {id: 18, name: 'Tint Wiz'},
    {id: 19, name: 'VinSolutions'},
    {id: 20, name: 'Vital Software'},
]

const OEMs = [
    {id: 1, name: 'Mercedes-Benz'},
    {id: 2, name: 'Volvo'},
    {id: 3, name: 'Lexus'},
    {id: 4, name: 'Honda'},
    {id: 5, name: 'BMW Motorrad'},
    {id: 6, name: 'BMW'},
    {id: 7, name: 'MINI'},
    {id: 8, name: 'Subaru'},
    {id: 9, name: 'INFINITI'},
    {id: 10, name: 'Mazda'},
    {id: 11, name: 'Porsche'},
    {id: 12, name: 'Toyota'},
    {id: 13, name: 'Independent Default'},
    {id: 14, name: 'Ford'},
    {id: 15, name: 'KIA'},
    {id: 16, name: 'Harley-Davidson'},
    {id: 17, name: 'Acura'},
    {id: 18, name: 'Alfa Romeo'},
    {id: 19, name: 'Audi'},
    {id: 20, name: 'Buick'},
    {id: 21, name: 'Cadillac'},
    {id: 22, name: 'Chevrolet'},
    {id: 23, name: 'Chrysler'},
    {id: 24, name: 'Dodge'},
    {id: 25, name: 'Fiat'},
    {id: 26, name: 'Genesis'},
    {id: 27, name: 'GMC'},
    {id: 28, name: 'Hyundai'},
    {id: 29, name: 'Jaguar'},
    {id: 30, name: 'Jeep'},
    {id: 31, name: 'Land Rover'},
    {id: 32, name: 'Lincoln'},
    {id: 33, name: 'Lucid'},
    {id: 34, name: 'Mitsubishi'},
    {id: 35, name: 'Nissan'},
    {id: 36, name: 'Polestar'},
    {id: 37, name: 'Ram'},
    {id: 38, name: 'Rivian'},
    {id: 39, name: 'Tesla'},
    {id: 40, name: 'Volkswagen'},
    {id: 41, name: 'Suzuki'},
    {id: 42, name: 'Ducati'},
    {id: 43, name: 'Yamaha'},
    {id: 44, name: 'Kawasaki'},
    {id: 45, name: 'KTM'},
    {id: 46, name: 'Triumph'},
    {id: 47, name: 'Aprilia'},
    {id: 48, name: 'Curtis'},
    {id: 49, name: 'Indian'},
    {id: 50, name: 'Hero'},
    {id: 51, name: 'United'},
    {id: 52, name: 'Confederate'},
    {id: 53, name: 'Can-Am'},
    {id: 54, name: 'Moto Guzzi'},
    {id: 55, name: 'Norton'},
    {id: 56, name: 'Brough Superior'},
    {id: 57, name: 'MV Agusta'},
    {id: 58, name: 'Benelli'},
    {id: 59, name: 'Buell Motorcycle Company'},
    {id: 60, name: 'Bimota'},
    {id: 61, name: 'AJS'},
    {id: 62, name: 'American Ironhorse'},
    {id: 63, name: 'Thor Industries'},
    {id: 64, name: 'Forest River'},
    {id: 65, name: 'Oliver Travel Trailers'},
    {id: 66, name: 'Coachmen RV'},
    {id: 67, name: 'Entrega Coach'},
    {id: 68, name: 'Newmar'},
    {id: 69, name: 'Jayco'},
    {id: 70, name: 'Tiffin Motorhomes'},
    {id: 71, name: 'Airstream'},
    {id: 72, name: 'Winnebago Industries'},
    {id: 73, name: 'Bertram'},
    {id: 74, name: 'Boston Whaler'},
    {id: 75, name: 'Chaparral'},
    {id: 76, name: 'Grady-White'},
    {id: 77, name: 'Lund'},
    {id: 78, name: 'Matercraft'},
    {id: 79, name: 'Sea Ray'},
    {id: 80, name: 'Tracker'},
    {id: 81, name: 'Viking Yachts'},
    {id: 82, name: 'Beneteau'},
    {id: 83, name: 'Nautique'},
    {id: 84, name: 'Pro-Line'},
    {id: 85, name: 'Godfrey Pontoon Boats'},
    {id: 86, name: 'Bayliner'},
    {id: 87, name: 'Sea-Doo'},
    {id: 88, name: 'Wellcraft'},
    {id: 89, name: 'Star-Craft'},
    {id: 90, name: 'Polaris'},
    {id: 91, name: 'Textron'},
    {id: 92, name: 'Ski-Doo'},
    {id: 93, name: 'Arctic Cat'},
    {id: 94, name: 'Kubota'},
].sort((a, b) => a.name.localeCompare(b.name))

const LocationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Location ID" source="location_id" alwaysOn/>
        <TextInput label="Name" source="name" alwaysOn/>
        <SelectInput label="OEM" source="oem_id" choices={OEMs}/>
        <DateInput source="created_after"/>
        <DateInput source="created_before"/>

    </Filter>
)

export const LocationList: ComponentType = () => {
    return (
        <List filters={<LocationFilter/>}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField source="zip"/>
                <DateField source="created_at"/>
                <EditButton/>
            </Datagrid>
        </List>
    );
}

const LocationTitle = ({record}: { record: Location }) => {
    return <span>Location {record ? `"${record.name}"` : ''}</span>;
};

const DeliveryFields = [
    "filter_email_resend_leads",
    "filter_oem_resend_leads",
    "filter_crm_resend_leads",
    "cpo_vehicles_email_delivery_enabled",
    "cpo_vehicles_oem_delivery_enabled",
    "cpo_vehicles_crm_delivery_enabled",
    "filter_incomplete_leads",
    "invoice_paid_delivery_enabled",
    "invoice_partial_refund_delivery_enabled",
    "invoice_refunded_delivery_enabled",
    "new_vehicles_email_delivery_enabled",
    "new_vehicles_oem_delivery_enabled",
    "new_vehicles_crm_delivery_enabled",
    "service_email_delivery_enabled",
    "service_oem_delivery_enabled",
    "service_crm_delivery_enabled",
    "parts_email_delivery_enabled",
    "parts_oem_delivery_enabled",
    "parts_crm_delivery_enabled",
    "other_email_delivery_enabled",
    "other_oem_delivery_enabled",
    "other_crm_delivery_enabled",
    "used_oem_vehicles_email_delivery_enabled",
    "used_oem_vehicles_oem_delivery_enabled",
    "used_oem_vehicles_crm_delivery_enabled",
    "used_vehicles_email_delivery_enabled",
    "used_vehicles_oem_delivery_enabled",
    "used_vehicles_crm_delivery_enabled",
]

const EditDeliveryFields = [
    ...DeliveryFields.map((source) => <BooleanInput key={source}
                                                    source={source}/>),
]
const CreateDeliveryFields = [
    ...DeliveryFields.map((source) =>
        <FormDataConsumer subscription={{values: true}}>
            {({formData, ...rest}) => formData.oem_id &&
                <BooleanInput key={source} source={source}
                              defaultValue={(oemRules[
                                  OEMs.find(oemItem => oemItem.id === formData.oem_id).name
                                  ] || independentRules).includes(source)
                              }
                />
            }
        </FormDataConsumer>),
]
const Fields = [
    <BooleanInput key='is_active' defaultValue={true} source='is_active'/>,
    <TextInput key='name' source='name'/>,
    <TextInput key='oem_code' source='oem_code'/>,
    <SelectInput defaultValue={null} emptyValue={null} source="oem_id" validate={[required()]}
                 choices={OEMs}/>,
    <SelectInput source="vendor" choices={[
        {id: 'podium', name: 'podium'},
        {id: 'universal lead', name: 'universal lead'},
    ]}/>,
    <SelectInput defaultValue={null} emptyValue={null} source="crm_id" choices={CRMs}/>,
    <TextInput key='zip' source='zip'/>,
    <TextInput key='parts_department_code' source='parts_department_code'/>,
    <TextInput key='service_department_code'
               source='service_department_code'/>,
    <SelectInput source="primary_department_code" defaultValue={'service'}
                 choices={[
                     {id: 'service', name: 'Service Department Code'},
                     {id: 'parts', name: 'Parts Department Code'},
                 ]}/>,
    <TextInput label='Fortellis Subscription ID'
               key='fortellis_subscription_id'
               source='fortellis_subscription_id'/>,
    <TextInput label='Merchant ID' key='merchant_id' source='merchant_id'/>,
    <ArrayInput source="email_forwarding_addresses">
        <SimpleFormIterator>
            <TextInput source=""/>
        </SimpleFormIterator>
    </ArrayInput>,
    <SelectInput source="email_structure"
                 defaultValue={'attachment_and_content'} choices={[
        {id: 'attachment_and_content', name: 'Attachment & Message in Body'},
        {id: 'content', name: 'Message In Body only'},
        {id: 'attachment', name: 'Attachment only'},
    ]}/>,
]

const LocationCreateToolbar = props => {
    const { reset } = useFormContext();
    const toolBarSx = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 10,
    }
    
    return (
      <Toolbar {...props} sx={toolBarSx}>
          <SaveButton/>
          <SaveButton
            label="Save and Create new"
            type="button"
            mutationOptions={{
                onSuccess() {
                    reset();
                }
            }}
          />
      </Toolbar>
    )
};

export const LocationEdit: ComponentType = (): JSX.Element => {
    const editContext = useEditContext();
    const record = editContext.record;
    
    return (
        <Edit title={<LocationTitle record={record}/>}>
            <SimpleForm validate={validateLocation}>
                <TextInput label='Location UID' source='id' disabled/>
                <DateInput label='Created at' source='created_at' disabled/>
                <DateInput label='Updated at' source='updated_at' disabled/>
                {Fields}
                {EditDeliveryFields}
            </SimpleForm>
        </Edit>
    )
}

export const LocationCreate: ComponentType = () => {
    return (
        <Create title="Location">
            <SimpleForm toolbar={<LocationCreateToolbar/>}
                        validate={validateLocation}>
                <TextInput label='Location UID' source='id'
                           validate={[required()]}/>
                {Fields}
                {CreateDeliveryFields}
            </SimpleForm>
        </Create>
    )
}

const validateLocation = (values) => {
    const errors = {};

    if (!values.name) {
        errors['name'] = 'The name is required';
    }

    if (

        values.invoice_paid_delivery_enabled
        || values.invoice_partial_refund_delivery_enabled
        || values.invoice_refunded_delivery_enabled
    ) {
        if (!values.zip) {
            errors['zip'] = 'The zip is required when one of the invoice delivery is enabled';
        }
        if (!values.primary_department_code) {
            errors['primary_department_code'] =
                'The primary department code is required when one of the invoice delivery is enabled';
        } else {
            if (values.primary_department_code === 'parts' &&
                !values.parts_department_code) {
                errors['parts_department_code'] =
                    'The parts department code is required ' +
                    'when primary department code is parts department code';
            }
            if (values.primary_department_code === 'service' &&
                !values.service_department_code) {
                errors['service_department_code'] =
                    'The service department code is required ' +
                    'when primary department code is service department code';
            }
        }
        if (!values.fortellis_subscription_id) {
            errors['fortellis_subscription_id'] = 'The Fortellis Subscription ID is ' +
                'required when one of the invoice delivery is enabled';
        }
        if (!values.merchant_id) {
            errors['merchant_id'] = 'The Merchant ID is ' +
                'required when one of the invoice delivery is enabled';
        }

    }
    if (
        (
            values.new_vehicles_oem_delivery_enabled
            || values.cpo_vehicles_oem_delivery_enabled
            || values.used_oem_vehicles_oem_delivery_enabled
            || values.service_oem_delivery_enabled
            || values.parts_oem_delivery_enabled
            || values.other_oem_delivery_enabled
        )
        && !values.oem_code
    ) {
        errors['oem_code'] = 'The oem_code is required when one of the oem delivery is enabled';
    }
    if (
        (
            values.new_vehicles_crm_delivery_enabled
            || values.cpo_vehicles_crm_delivery_enabled
            || values.used_oem_vehicles_crm_delivery_enabled
            || values.service_crm_delivery_enabled
            || values.parts_crm_delivery_enabled
            || values.other_crm_delivery_enabled
        )
        && !values.crm_id
    ) {
        errors['crm_id'] = 'The crm is required when one of the crm delivery is enabled';
    }
    if (values.id) {
        const idRegexp = /^[a-zA-Z0-9-]+$/;
        if (values.id.search(idRegexp) === -1) {
            errors['id'] = 'Incorrect Location UID format';
        }
    }
    if ([12, 13].includes(values.oem_id)
        && (
            !values.email_forwarding_addresses
            || !values.email_forwarding_addresses.length
            || !values.email_forwarding_addresses[0]
        )
    ) {
        errors['email_forwarding_addresses'] =
            'Email forwarding address is required for Toyota';

    }
    return errors
};
