import {AuthProvider, UserIdentity} from "react-admin";
import {authenticate, getSession, logout} from "./cognito";

const authProvider: AuthProvider = {
    login: ({username, password}: { username: string, password: string }) => {
        return authenticate(username, password);
    },
    getIdentity(): Promise<UserIdentity> {
        return getSession()
            .then((session) => {
                if (session === null) {
                    return Promise.reject("session is null")
                }

                return {
                    id: session.attributes.email,
                    fullName: session.attributes.email,
                }
            })
    },
    checkAuth(): Promise<void> {
        return getSession()
            .then((session) => {
                if (session === null) {
                    return Promise.reject()
                } else {
                    return Promise.resolve()
                }
            })
    },
    checkError(error): Promise<void> {
        console.error('error', error)

        const status = error.status;
        if (status === 401 || status === 403) {
            logout();
            return Promise.reject();
        }

        return Promise.resolve();
    },
    getPermissions(): Promise<string> {
        return getSession()
            .then((session) => {
                if (session === null) {
                    return Promise.reject('TODO')
                } else {
                    return Promise.resolve('TODO')
                }
            })
    },
    logout(): Promise<void | false | string> {
        logout();
        return Promise.resolve();
    }

};

export default authProvider;
