import React from 'react';
import {Admin, Resource} from 'react-admin';
import dataProvider from "./dataProvider";
import {LocationCreate, LocationEdit, LocationList} from "./locations";
import authProvider from "./auth/authProvider";
import {ResourceType} from "./constants";
import LocationIcon from '@mui/icons-material/LocationOn';

function App(): JSX.Element {
    return (
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
        >
            <Resource
                name={ResourceType.Locations}
                list={LocationList}
                edit={LocationEdit}
                create={LocationCreate}
                icon={LocationIcon}
            />
        </Admin>
    );
}

export default App;
