const defaultRules = [
    "filter_email_resend_leads",
    "filter_oem_resend_leads",
    "cpo_vehicles_oem_delivery_enabled",
    "new_vehicles_oem_delivery_enabled",
    "service_email_delivery_enabled",
    "parts_email_delivery_enabled",
    "other_email_delivery_enabled",
    "used_oem_vehicles_oem_delivery_enabled",
    "used_vehicles_email_delivery_enabled",
]

export const independentRules = [
    "filter_email_resend_leads",
    "cpo_vehicles_email_delivery_enabled",
    "new_vehicles_email_delivery_enabled",
    "service_email_delivery_enabled",
    "parts_email_delivery_enabled",
    "other_email_delivery_enabled",
    "used_oem_vehicles_email_delivery_enabled",
    "used_vehicles_email_delivery_enabled",
]

export const oemRules = {
    "Mercedes-Benz": [
        "filter_email_resend_leads",
        "cpo_vehicles_email_delivery_enabled",
        "filter_incomplete_leads",
        "new_vehicles_email_delivery_enabled",
        "service_email_delivery_enabled",
        "parts_email_delivery_enabled",
        "other_email_delivery_enabled",
        "used_oem_vehicles_email_delivery_enabled",
        "used_vehicles_email_delivery_enabled",
    ],
    "Volvo": [...defaultRules, ...["filter_incomplete_leads"]],
    "Lexus": [
        "filter_email_resend_leads",
        "filter_oem_resend_leads",
        "cpo_vehicles_oem_delivery_enabled",
        "filter_incomplete_leads",
        "new_vehicles_oem_delivery_enabled",
        "service_email_delivery_enabled",
        "parts_email_delivery_enabled",
        "other_email_delivery_enabled",
        "used_oem_vehicles_email_delivery_enabled",
        "used_vehicles_email_delivery_enabled",
    ],
    "Honda": [],
    "BMW Motorrad": defaultRules,
    "BMW": defaultRules,
    "MINI": defaultRules,
    "Subaru": defaultRules,
    "INFINITI": [
        "filter_email_resend_leads",
        "filter_oem_resend_leads",
        "cpo_vehicles_oem_delivery_enabled",
        "new_vehicles_oem_delivery_enabled",
        "service_email_delivery_enabled",
        "service_oem_delivery_enabled",
        "parts_email_delivery_enabled",
        "parts_oem_delivery_enabled",
        "other_email_delivery_enabled",
        "other_oem_delivery_enabled",
        "used_oem_vehicles_oem_delivery_enabled",
        "used_vehicles_email_delivery_enabled",],
    "Mazda": defaultRules,
    "Porsche": defaultRules,
    "Toyota": [
        "cpo_vehicles_email_delivery_enabled",
        "new_vehicles_email_delivery_enabled",
        "service_email_delivery_enabled",
        "parts_email_delivery_enabled",
        "other_email_delivery_enabled",
        "used_oem_vehicles_email_delivery_enabled",
        "used_vehicles_email_delivery_enabled",
    ],
    "Independent Default": independentRules,
    "Ford": [
        "filter_email_resend_leads",
        "filter_oem_resend_leads",
        "cpo_vehicles_oem_delivery_enabled",
        "new_vehicles_oem_delivery_enabled",
        "service_oem_delivery_enabled",
        "parts_oem_delivery_enabled",
        "other_oem_delivery_enabled",
        "used_oem_vehicles_oem_delivery_enabled",
    ],
    "KIA": defaultRules,
    "Harley-Davidson": defaultRules,
    "Alfa Romeo": defaultRules,
    "Chrysler": defaultRules,
    "Dodge": defaultRules,
    "Fiat": defaultRules,
    "Jeep": defaultRules,
    "Ram": defaultRules,
    "Audi": defaultRules,
};
