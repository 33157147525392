import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import {useEffect, useState} from "react";
import {COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID} from "../constants";

let userPool: CognitoUserPool;

export function createUserPool(params: ICognitoUserPoolData): CognitoUserPool {
    return new CognitoUserPool(params);
}

function getUserPool(): CognitoUserPool {
    if (userPool !== undefined) {
        return userPool;
    }
    userPool = createUserPool({
        UserPoolId: COGNITO_USER_POOL_ID,
        ClientId: COGNITO_CLIENT_ID,
    });
    return userPool;
}

function getSessionAsync(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        user.getSession((
            error: Error | null,
            session: CognitoUserSession | null,
        ) => {
            if (error) {
                return reject(error);
            }

            if (session) {
                return resolve(session);
            }

            reject('Impossible state.');
        })
    });
}

function promisedGetAttributes(user: CognitoUser): Promise<Array<CognitoUserAttribute>> {
    return new Promise((resolve, reject) => {
        user.getUserAttributes((
            error,
            session,
        ) => {
            if (error) {
                return reject(error);
            }

            if (session) {
                return resolve(session);
            }

            reject('Impossible state');
        })
    });
}

export type Attributes = Record<string, string>;

export interface UserAttributes extends Attributes {
    email: string,
}

function formatAttributes(attributes: Array<CognitoUserAttribute>): UserAttributes {
    return attributes.reduce((acc, attr) => ({
        ...acc,
        [attr.getName()]: attr.getValue()
    }), {} as UserAttributes);
}

export interface UserSession {
    user: CognitoUser,
    session: CognitoUserSession,
    attributes: UserAttributes,
}

export async function getSession(): Promise<UserSession | null> {
    const user = getUserPool().getCurrentUser();

    if (user === null) {
        return null;
    }

    return {
        user,
        session: await getSessionAsync(user),
        attributes: formatAttributes(await promisedGetAttributes(user)),
    };
}

export async function getIdToken(): Promise<string | null> {
    const session = await getSession();
    return session?.session.getIdToken().getJwtToken() || null;
}

function useGetSession(): UserSession | null {
    const [session, setSession] = useState<UserSession | null>(null)

    useEffect(() => {
        getSession()
            .then((session) => {
                setSession(session);
            })
    }, [])

    return session
}

export function authenticate(
    Username: string,
    Password: string,
): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username,
            Pool: getUserPool(),
        });

        const authenticationDetails = new AuthenticationDetails({
            Username,
            Password,
        });

        user.authenticateUser(authenticationDetails, {
            onSuccess: (data) => {
                resolve(data);
            },
            onFailure: (error) => {
                reject(error);
            },
            newPasswordRequired: (data) => {
                resolve(data);
            }
        });
    });
}

export function logout(): void {
    getUserPool().getCurrentUser()?.signOut();
    window.location.assign('/#/login');
}

export function getUser(): CognitoUser | null {
    return getUserPool().getCurrentUser();
}

export const AuthContext = {
    getUserPool,
    getSession,
    useGetSession,
    authenticate,
    logout,
    getUser,
};
