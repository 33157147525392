export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || "";
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID || "";
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || "us-east-1";
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID || "";
export const LEAD_RESULTS_BUCKET = process.env.REACT_APP_LEAD_RESULTS_BUCKET || "";


export enum ResourceType {
    Locations = 'locations',
}