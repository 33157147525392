import {DataProvider, fetchUtils} from 'react-admin';
import {getIdToken} from "./auth/cognito";
import {ResourceType} from "./constants";
import {Location} from "./locations";

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = (url: string, options: RequestInit = {}) => {
    return getIdToken()
        .then((token) => {
            if (!options.headers) {
                options.headers = new Headers()
            }
            (options.headers as Headers).set('Authorization', token || '');
            return fetchUtils.fetchJson(url, options);
        })
};

const dataProvider: DataProvider = {
    getList: (resource, params) => {

        const queryParams = [];
        queryParams.push("page=" + encodeURIComponent(params['pagination']['page']));
        queryParams.push("page_size=" + encodeURIComponent(params['pagination']['perPage']));
        if ('oem_id' in params['filter']) {
            queryParams.push("oem_id=" + encodeURIComponent(params['filter']['oem_id']));
        }
        if ('location_id' in params['filter']) {
            queryParams.push("location_id=" + encodeURIComponent(params['filter']['location_id']));
        }
        if ('name' in params['filter']) {
            queryParams.push("name=" + encodeURIComponent(params['filter']['name']));
        }
        if ('created_after' in params['filter']) {
            queryParams.push("created_after=" + encodeURIComponent(params['filter']['created_after']));
        }
        if ('created_before' in params['filter']) {
            queryParams.push("created_before=" + encodeURIComponent(params['filter']['created_before']));
        }
        const urlParams = queryParams.join("&");

        const url = `${apiUrl}/${resource}?${urlParams}`;
        return httpClient(url).then(({json}) => {
            if (resource === ResourceType.Locations) {
                const sortFiled = params.sort.field;
                let items: Array<Location> = json;
                items = items
                    .sort((a, b) => {
                        const field = sortFiled as keyof Location
                        const valueA = a[field];
                        const valueB = b[field];

                        if (valueA === valueB) {
                            return 0;
                        }

                        return (
                            ((Number((valueA < valueB)) * 2) - 1) *
                            ((Number(params.sort.order === "ASC") * (-2)) + 1)
                        );
                    });
                return {
                    data: items,
                    total: params.pagination.perPage * (params.pagination.page + 1),
                }
            }

            return {
                data: json,
                // TODO
                total: json.length,
            }
        }).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
            data: json,
        })).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        }),

    getMany: (resource) => {
        const url = `${apiUrl}/${resource}`;
        return httpClient(url).then(({json}) => ({data: json})).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        });
    },

    getManyReference: (resource) => {
        const url = `${apiUrl}/${resource}?`;

        return httpClient(url).then(({json}) => ({
            data: json,
            total: 10,
        })).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        });
    },

    update: (resource, params) => {
        const data = params.data
        delete data['updated_at']
        delete data['created_at']
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(({json}) => ({data: json})).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        })
    },

    updateMany: (resource, params) => {
        const id = params.ids[0];
        return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json})).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        })
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(() => ({
            data: {...params.data},
        })).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        }),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        }),

    deleteMany: (resource, params) => {
        if (resource === ResourceType.Locations) {
            const ids = params.ids;

            const promises = []
            for (const id of ids) {
                promises.push(httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                }))
            }

            return Promise.all(promises)
                .then(() => Promise.resolve({data: ids}))
        }

        return httpClient(`${apiUrl}/${resource}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})).catch((e) => {
            console.error(e);
            return Promise.reject(e);
        });
    }
};

export default dataProvider;